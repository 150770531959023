<template>
  <v-card flat>
    <!-- three-line: Increases list-item height for three lines.
      This prop uses line-clamp and is not supported in all browsers.
    -->
    <v-list three-line>
      <v-list-item>
        <!-- prompt icon -->
        <v-list-item-avatar>
          <v-icon large :color="iconColorClass">
            {{ iconPrompt }}
          </v-icon>
        </v-list-item-avatar>

        <!-- prompt -->
        <v-list-item-content :class="promptTextClass">
          {{ promptText }}
          <v-list-item-subtitle v-html="subtitle" v-show="visibleSubtitle">
          </v-list-item-subtitle>
        </v-list-item-content>

        <!-- prompt hint action -->
        <v-list-item-action v-show="visibleHint">
          <base-tooltip-icon
            top
            :icon-name="iconHint"
            :icon-color-class="iconColorClass"
          >
            {{ hint }}
          </base-tooltip-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
// design
import {
  createPrompt,
  promptType
} from "@/design/components/prompt/promptModel";
import { iconMessageQuestion, iconQuestion } from "@/design/icon/iconConst";
import { colorTheme } from "@/design/colors/Color";

// utils
import { isEmpty } from "@/utils";
import { displayTypes, fontEmphasis, Text } from "@/design/text/Text";

export default {
  name: "BasePrompt",
  components: {
    BaseTooltipIcon: () => import("@/components/shared/base/BaseTooltipIcon")
  },
  data() {
    return {
      iconPrompt: iconQuestion,
      iconHint: iconMessageQuestion
    };
  },
  props: {
    /**
     * @type {{text: string, subtitle: (string|undefined|null), hint: (string|undefined|null), type: (string|undefined|null)}}
     */
    prompt: createPrompt("Prompt here ...", undefined, undefined, undefined)
  },
  computed: {
    /**
     * visible Subtitle
     * @return {boolean} true if visible Subtitle
     */
    visibleSubtitle() {
      return !isEmpty(this.prompt?.subtitle);
    },

    /**
     * visible Hint
     * @return {boolean} true if visible Hint
     */
    visibleHint() {
      return !isEmpty(this.prompt?.hint);
    },

    /**
     * prompt Text
     * @return {string} prompt Text
     */
    promptText() {
      return this.prompt?.text;
    },

    /**
     * subtitle of the prompt
     * @return {string | undefined | null} subtitle of the prompt
     */
    subtitle() {
      return this.prompt?.subtitle;
    },

    /**
     * hint (will be displayed as tool tip)
     * @return {string | undefined | null} hint which will be displayed as tool tip
     */
    hint() {
      return this.prompt?.hint ?? undefined;
    },

    /**
     * compute prompt Type
     * @return {string} prompt Type
     */
    promptType() {
      return this.prompt?.type ?? promptType.normal;
    },

    /**
     * compute icon Color Class
     * @return {string|undefined} icon Prompt Color Class
     */
    iconColorClass() {
      switch (this.promptType) {
        case promptType.normal: {
          return undefined;
        }
        case promptType.warning: {
          return colorTheme.warning;
        }
        case promptType.info: {
          return colorTheme.info;
        }
        case promptType.success: {
          return colorTheme.success;
        }
        case promptType.critical: {
          return colorTheme.error;
        }
        default:
          return undefined;
      }
    },

    promptTextClass() {
      return new Text(
        undefined,
        displayTypes.subtitle1,
        fontEmphasis.regular
      ).getClassText();
    }
  }
};
</script>
